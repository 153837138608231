import HowDoWeUseSpaceCard from "./HowDoWeUseSpaceCard";

const HowDoWeUseSpace = () => {
    return (
        <div  className="relative ">
            <img className=" z-10 absolute top-0 object-top object-cover max-lg:h-[100px] h-[207px] w-full"
                 src="landing/Starfield.svg" alt=""/>
            <div className=" mx-auto p-8 max-w-5xl flex flex-col gap-4">
                <div
                    data-aos="fade-up"
                    className="z-20 leading-none  text-start  tracking-tight koulen text-[48px] lg:text-[64px] text-landing-yellow">How
                    do we use space?
                </div>
                <div data-aos="fade-up" className="leading-tight   text-start text-[24px] fredoka">
                    Satellites orbiting around the earth allow us to...
                </div>
            </div>
            <div className=" p-8 pt-16 max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-3 gap-8 ">
                <HowDoWeUseSpaceCard delay="0" image={'landing/Climate.svg'}
                                     description={'Monitor our climate and changes across our planet to improve life for people everywhere'}/>
                <HowDoWeUseSpaceCard delay="300" image={'landing/GPS.svg'}
                                     description={'Use our phones to navigate and track everything from takeaway deliveries to trains'}/>
                <HowDoWeUseSpaceCard delay="600" image={'landing/Weather.svg'}
                                     description={'Better predict and respond to extreme weather effects and natural disasters to support people in need'}/>

            </div>
        </div>
    )
}
export default HowDoWeUseSpace;