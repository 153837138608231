import {Link} from "react-router-dom";
import {useState} from "react";

const FindOutMore = () => {
    const [answered, setAnswered] = useState(false);

    return (<div className=" relative pt-10">
        <div
            className="relative z-0"
        >
            <img className="mx-8" src="landing/Clouds.svg" alt=""/>
            <img className="h-[200px] w-full object-cover" src="landing/Starfield.svg" alt=""/>

            <div className="px-8 ">
                <div
                    className="slow-floating w-fit mx-auto text-center leading-none pb-12  tracking-tight koulen text-[48px] lg:text-[64px] text-landing-yellow">
                    Find Out More
                </div>
                <div
                    className="max-w-4xl mx-auto slow-floating  bg-landing-yellow rounded-md p-6 lg:p-8 text-[24px] text-landing-blue">
                    <p>To find out more about the different job opportunities available, and how you can prepare for
                        them, head to our
                        <Link className="font-bold hover:underline" to={'/careers_resources/'}> Careers
                            Resources</Link> section.</p>
                </div>

            </div>


            <div className=" flex pt-24 flex-col items-center gap-8">
                <div
                    className="text-center leading-none   tracking-tight koulen text-[32px] lg:text-[36px] text-landing-yellow">Did
                    you find this page useful?
                </div>

                <div className="w-full relative">
                    {answered ? (
                        <div className="mx-auto text-center text-[24px]">Thank you for your feedback!</div>
                    ) : (
                        <div className="px-8 flex-wrap flex justify-center w-full gap-8">
                            <button onClick={()=>setAnswered(true)} className=" flex justify-center landing-button py-2.5 text-center rounded-md bg-landing-yellow fredoka-bold text-landing-blue text-2xl px-12">
                                <img className="h-7 " src="landing/smile.svg" alt=""/>
                            </button>
                            <button onClick={()=>setAnswered(true)} className="flex justify-center landing-button py-2.5 text-center rounded-md bg-landing-yellow fredoka-bold text-landing-blue text-2xl px-12">
                                <img className="h-7 " src="landing/frown.svg" alt=""/>
                            </button>
                        </div>
                    )}

                </div>

            </div>


            <div className="flex justify-end items-center">
                <img className="h-[200px] object-cover xl:mt-10 my-24 " src="landing/Hills.svg" alt=""/>
            </div>
            <img className="absolute z-0 bottom-0 object-top w-[120vw] overflow-hidden   h-[220px] object-cover "
                 src="landing/Base2.svg" alt=""/>
        </div>

    </div>)
}

export default FindOutMore;