import React, {useEffect, useRef, useState} from 'react';

const ShootingStar = ({className}) => {
    const [hasAnimated, setHasAnimated] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const starRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    const {top, bottom} = entry.boundingClientRect;
                    const viewportHeight = window.innerHeight;
                    if (bottom > viewportHeight * 0.8) {
                        if (!hasAnimated) {
                            setIsVisible(true);
                            setHasAnimated(true);
                        }
                    }
                } else {
                    setIsVisible(false);
                }
            },
            {threshold: 0}
        );

        if (starRef.current) {
            observer.observe(starRef.current);
        }

        return () => {
            if (starRef.current) {
                observer.unobserve(starRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isVisible) {
            setHasAnimated(false);
        }
    }, [isVisible]);

    return (
        <div className={`${className} !h-[60px]`} ref={starRef}>
            <img className={`shooting-star !h-[20px] md:!h-[60px] ${isVisible ? 'animate ' : 'hidden'}`}
                 src="landing/ShootingStar.svg" alt="Shooting Star"/>
        </div>
    );
}

export default ShootingStar;