const StarfieldBg = ({opacity, children }) => {
  return (
      <div className="relative">
          <div
              style={{
                  backgroundImage: `url('landing/Starfield.svg')`,
                  backgroundRepeat: 'repeat-y',
                  backgroundSize: '100% auto',
                  backgroundPosition: 'top left',
                  opacity: opacity,
              }}
              className=" absolute inset-0 z-0"
          >
          </div>
          {children}
      </div>
  );
}
export default StarfieldBg;