const Salaries = () => {
    return (
        <div data-aos={'fade-up'} className="max-w-[1160px] mx-auto relative max-lg:flex-col gap-12 flex justify-center p-8  pb-12 pt-6 ">
            <div
                className=" w-full  bg-landing-yellow p-6 px-12 flex max-md:flex-col gap-8 rounded-md items-center">
                <div className="flex max-lg:flex-col gap-4">
                    <div className=" text-[24px] w-full lg:w-1/2 text-landing-blue">Salaries in the space sector are above the national
                        average and could increase as demand for people increases.
                    </div>
                    <div className="w-full lg:w-1/2 text-[24px] flex flex-col gap-6 items-center justify-center text-landing-blue">
                        <div className="font-bold text-center">Average Salaries</div>
                        <div className="flex flex-wrap max-md:flex-col justify-center gap-6">
                            <div className="leading-none flex flex-col gap-0 justify-center items-center">
                                <div className="text-landing-blue font-bold text-[40px]">£31k</div>
                                <div className="text-landing-blue text-[24px]">Junior</div>
                            </div>
                            <div className="leading-none flex flex-col gap-0 justify-center items-center">
                                <div className="text-landing-blue font-bold text-[40px]">£48k</div>
                                <div className="text-landing-blue text-[24px]">Mid-level</div>
                            </div>
                            <div className="leading-none flex flex-col gap-0 justify-center items-center">
                                <div className="text-landing-blue font-bold text-[40px]">£66k</div>
                                <div className="text-landing-blue text-[24px]">Senior</div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <img className="floating max-lg:block lg:absolute -top-16 lg:right-4 xl:-right-16 h-[131px]  "
                 src="landing/Saturn.svg" alt=""/>
        </div>
    );
}
export default Salaries;