import ShootingStar from "./ShootingStar";
import React, {useEffect, useRef} from "react";
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Astronaut = () => {
    const astronautRef = useRef(null);
    const floatingAnimationRef = useRef(null);

    useEffect(() => {
        gsap.set(astronautRef.current, {autoAlpha: 0});
        gsap.to(astronautRef.current, {autoAlpha: 1, duration: 0.5});

       gsap.to(".astronaut-container", {
            y: "-=10", // Move up by 10 pixels
            repeat: -1, // Repeat indefinitely
            yoyo: true, // Go back and forth
            duration: 1.5, // Duration of the float effect
            ease: "sine.inOut" // Smooth easing
        });

        const scrollTrigger = gsap.to(astronautRef.current, {
            scrollTrigger: {
                trigger: "#astronaut",
                start: "top top",
                end: "+=1000",
                scrub: 1,
                pin: true,
                snap: 1,
                onUpdate: (self) => {
                    const newX = window.innerWidth  * self.progress;
                    const newY = window.innerHeight * 0.25 * self.progress;
                    const newScale = 1 + 0.5 * self.progress;

                    gsap.to(".astronaut-image", {
                        autoAlpha: self.progress < 0.05 ? 0 : 1,
                        x: newX,
                        y: newY,
                        rotation: 13,
                        scaleX: newScale,
                        scaleY: newScale,
                        overwrite: true,
                    });
                },
            }
        });

        return () => {
            scrollTrigger.scrollTrigger.kill();
            gsap.set(astronautRef.current, {autoAlpha: 1});
        };
    }, []);

    return (
        <div id="astronaut" className="relative overflow-hidden min-h-screen w-full max-w-full">
            <div
                className="absolute inset-0"
                style={{
                    backgroundImage: `url('landing/Starfield.svg')`,
                    backgroundRepeat: 'repeat-y',
                    backgroundSize: '100% auto',
                    backgroundPosition: 'top left',
                    opacity: 0.2,
                }}
            />
            <div ref={astronautRef}
                 className=" astronaut-container top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2  absolute max-lg:flex-col flex w-screen   gap-16 items-center justify-center">
                <img
                    ref={floatingAnimationRef}
                    className="max-lg:hidden absolute left-0 astronaut-image h-[195px] mx-auto"
                    src="landing/SpaceStation.svg"
                    alt="Astronaut floating in space"
                />
                <img
                    data-aos="fade-up"
                    className="max-lg:block hidden  h-[100px] mx-auto"
                    src="landing/SpaceStation.svg"
                    alt="Astronaut floating in space"
                />
                <div data-aos="fade-up" className=" lg:ml-[25vw] p-8 text-[24px] max-w-md text-start flex items-center">
                    Also, astronauts living on space stations do science experiments to learn how being in space affects
                    the human body
                </div>
            </div>
        </div>
    );
};

export default Astronaut;