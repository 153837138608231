
import React, {useEffect, useRef, useState} from "react";
import JobOpportunity from "./JobOpportunity";
import gsap from 'gsap';
import * as rotationTween from "react-dom/test-utils";

const JobOpportunities = () => {
    const jobOpportunitiesLeft = [
        {
            id: 1,
            title: "Designing",
            description: "<p>Rockets and spacecraft are designed to be lightweight but strong enough to launch into space. Once in space, they have to generate power, change position, and be protected from high levels of radiation and extreme temperature differences - from 120°C in sunlight to -100°C in the shade! Computer programmes used to design and operate spacecraft also need to be coded.</p> <p><strong>Example jobs:</strong> Mechanical engineer, software engineer, electrical engineer, system engineers, thermal engineer</p>",
            icon: "landing/jobOpportunities/Designing.svg",
            active_icon: "landing/jobOpportunities/DesigningActive.svg",
            img: "landing/jobOpportunities/parth.jpg",
            worker: "Parth",
            href: "https://spacecareers.uk/articles/296c0403-11a7-4bb0-be21-4c2d8c6ca2cf"
        },
        {
            id: 2,
            title: "Building",
            description: "<p>When the spacecraft has been designed, technicians need to manufacture parts and assemble them together in a clean room to ensure the spacecraft is not contaminated. They must also test the spacecraft and its components and prepare the equipment that will be used to communicate with the satellite when it is in space.</p> <p><strong>Example jobs:</strong> Technician, CNC machinist, Assembly Integration & Test Engineer</p>",
            icon: "landing/jobOpportunities/Building.svg",
            active_icon: "landing/jobOpportunities/BuildingActive.svg",
            img: "landing/jobOpportunities/Kelsey.jpg",
            worker: "Kelsey",
            href: "https://nationalspaceacademy.org/careers/career-resources/kelsey-manufacturing-engineer/"
        },
        {
            id: 3,
            title: "Operating",
            description: "<p>From launching the spacecraft to ensuring that it is running correctly, people are needed to efficiently diagnose and solve any problems that occur. With the support of computer algorithms and radio antennas around the world, they guide and control spacecraft from their control centres, whether that’s a company’s office or a dedicated site like Goonhilly Earth Station in Cornwall.</p> <p><strong>Example jobs:</strong> Spacecraft Operator, Flight Controller, Flight Dynamics Engineer</p>",
            icon: "landing/jobOpportunities/Operating.svg",
            active_icon: "landing/jobOpportunities/OperatingActive.svg",
            img: "landing/jobOpportunities/Zamzam.jpg",
            worker: "Zamzam",
            href: "https://spacecareers.uk/articles/08f9e7d3-4a9d-4411-b12d-f088a4b95fdd"
        },
        {
            id: 4,
            title: "Analysing",
            description: "<p>Spacecraft and telescopes in space and on Earth provide us with images and data that scientists and others around the world analyse to progress our understanding of the universe and planet Earth. You could be investigating how stars and planets form, looking for extraterrestrial life, or monitoring changes to the Earth’s climate. Alternatively, you could be analysing the space sector itself and how businesses are performing!</p> <p><strong>Example jobs:</strong> Astronomer, Planetary Scientist, Astrobiologist, Data Scientist, Data Analyst</p>",
            icon: "landing/jobOpportunities/Analysing.svg",
            active_icon: "landing/jobOpportunities/AnalysingActive.svg",
            img: "landing/jobOpportunities/lewis.jpg",
            worker: "Lewis",
            href: "https://spacecareers.uk/articles/c4ad6439-994b-4d0b-8129-243cb21dc914"
        }
    ]

    const jobOpportunitiesRight = [
        {
            id: 5,
            title: "Communicating",
            description: "<p>Every organisation does some form of communication - whether that’s to, spread important messages, sell products, educate, or appeal to people searching for jobs. There are many different ways to do this such as creating online adverts, hosting podcasts, designing websites and producing videos.</p> <p><strong>Example jobs:</strong> Marketing Coordinator, Graphic Designer, Public Affairs Officer, Communications Specialist</p>",
            icon: "landing/jobOpportunities/Communicating.svg",
            active_icon: "landing/jobOpportunities/CommunicatingActive.svg",
            img: "landing/jobOpportunities/katie.jpg",
            worker: "Katie",
            href: "https://spacecareers.uk/articles/225a84dd-4185-4e59-b22d-7a1ead3a5861"
        },
        {
            id: 6,
            title: "Planning and Managing",
            description: "<p>No projects would be successful without people to plan how they will be delivered and keep them on track and within budget. These projects could be anything from developing a new marketing campaign to sending spacecraft across the solar system. You could be working with people from different organisations and across multiple countries, bringing them together to collaborate as one team with a shared objective, or managing the people and processes within your organisation.</p> <p><strong>Example jobs:</strong> Project Manager, Programme Manager, Operations Manager, Procurement Manager, Finance Officer</p>",
            icon: "landing/jobOpportunities/Planning.svg",
            active_icon: "landing/jobOpportunities/PlanningActive.svg",
            img: "landing/jobOpportunities/louise.jpg",
            worker: "Louise",
            href: "https://spacecareers.uk/articles/96ef7b69-377c-4198-ac77-c8e7036a6790"
        },
        {
            id: 7,
            title: "Making Policy or Law",
            description: "<p>The UK government sets its objectives and strategies for using space, and its departments, space agencies, and businesses work together to create rules to follow when exploring and using space to ensure space is safe, peaceful, and fair for everyone. These rules cover things like launching rockets, preventing space junk, and using resources on the Moon or asteroids.</p> <p><strong>Example jobs:</strong> Policy Officer, Space Lawyer, Government Affairs Officer, Licensing Specialist, Regulatory Officer</p>",
            icon: "landing/jobOpportunities/Law.svg",
            active_icon: "landing/jobOpportunities/LawActive.svg",
            img: "landing/jobOpportunities/julie.jpg",
            worker: "Julie",
            href: "https://spacecareers.uk/articles/3756a921-14de-47c2-a7d6-ae5c2fad5455"
        },
        {
            id: 8,
            title: "Leading or Selling",
            description: "<p>For space organisations to survive and grow they need to sell products and services, such as satellite components or useful information from space data they have analysed. They have to understand what customers want, come up with strategies to beat their competitors, and predict how the market might change in the future.</p> <p><strong>Example jobs:</strong> Sales Manager, Business Development Manager, Business Analyst, Key Account Manager</p>",
            icon: "landing/jobOpportunities/Leading.svg",
            active_icon: "landing/jobOpportunities/LeadingActive.svg",
            img: "landing/jobOpportunities/alex.jpg",
            worker: "Alex",
            href: "https://spacecareers.uk/articles/466b16c2-d5e9-4be9-a3c3-a85ffac1a4e3"
        }
    ]

    const [activeJobId, setActiveJobId] = useState(0);
    const [previousActiveJobId, setPreviousActiveJobId] = useState(null);

    const spinRef = useRef(null); // Use a ref to track the spin animation
    const elementSpinRef = useRef(null);

    useEffect(() => {
        if (window.innerWidth >= 1280) {
            const elements = document.querySelectorAll(" .job-opportunity-right, .job-opportunity-left");
            const wrapper = document.querySelector(".job-opportunity-wrapper");
            const wrapperWidth = wrapper.offsetWidth;
            const wrapperHeight = wrapper.offsetHeight;
            spinRef.current = gsap.to(wrapper, {
                duration: 50,
                rotation: 360,
                repeat: -1,
                ease: "none",
                paused: true, // Start paused
            });

            elementSpinRef.current=gsap.to('.job-opportunity-circle', {
                duration: 50,
                rotation: -360,
                repeat: -1,
                ease: "none",
                paused: true, // Start paused
            });


            // Elem méretek
            const elementWidth = 160;
            const elementHeight = 160;
            const radius = Math.min(wrapperWidth, wrapperHeight) / 2 - Math.max(elementWidth, elementHeight) / 2 - 50; // - érték a végén, a gap

            if (!activeJobId) {
                spinRef.current.play();
                elementSpinRef.current.play();
                elements.forEach((el, index) => {
                    const totalElements = elements.length;
                    const angle = (index / totalElements) * Math.PI * 2;

                    // new positions (circle)
                    const x = radius * Math.cos(angle) + (wrapperWidth / 2); // A wrapper középpontja
                    const y = radius * Math.sin(angle) + (wrapperHeight / 2); // A wrapper középpontja

                    // current positions
                    const currentXPosition = parseFloat(el.style.left) || 0;
                    const currentYPosition = parseFloat(el.style.top) || 0;

                    // calculate path
                    const targetX = el.classList.contains("job-opportunity-left")?x - currentXPosition: x - currentXPosition+55;
                    const targetY = y - currentYPosition-75;
                    gsap.to(el, {
                        x: targetX-80,
                        y: targetY,
                        duration: 1,
                        ease: "power2.inOut"

                        }
                    );

                });
            } else {
                spinRef.current.pause();
                elementSpinRef.current.pause();
                gsap.set(wrapper, {
                    rotation: 0,
                    duration: 1,
                    ease: "power2.inOut",
                });
                gsap.set(".job-opportunity-circle", {
                    rotation: 0,
                    duration: 1,
                    ease: "power2.inOut",
                });
                elements.forEach((el, index) => {
                    const angle = (index / elements.length) * Math.PI * 2;
                    const radius = wrapperHeight / 2 - 100;
                    const x = radius * Math.cos(angle);
                    const y = radius * Math.sin(angle);
                    gsap.to(el, {
                        x: 0,
                        y: 0,
                        duration: 1,
                        ease: "power2.inOut",
                    });
                });

            }
        }
        if(window.innerWidth<1280 && activeJobId){
            const activeJob =
                jobOpportunitiesLeft.find(job => job.id === activeJobId) ||
                jobOpportunitiesRight.find(job => job.id === activeJobId);

            if (activeJob) {
                const activeElement = document.getElementById(activeJob.title);
                if (activeElement) {
                    activeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
        }
        return () => {
            if (spinRef.current) {
                spinRef.current.kill();
            }
            if(elementSpinRef.current){
                elementSpinRef.current.kill();
            }
        };
    }, [activeJobId]);

    if (window.innerWidth >= 1280) {
        return (
            <div className="relative p-8 overflow-hidden  ">
                <div data-aos={"fade-in"}
                     className="py-20  max-w-5xl mx-auto  uppercase koulen text-landing-yellow text-3xl lg:text-[40px] text-center">
                    Find out what you could be doing in the future and meet some people doing these jobs....
                </div>
                <div
                    className=" job-opportunity-wrapper relative  min-h-[800px] flex max-xl:flex-col max-lg:justify-center items-center gap-8 max-lg:p-0 justify-between">

                    {jobOpportunitiesLeft.map((job, index) => (
                        <div key={job.id} style={{top: `${index * 165+50}px`, left:'40px'}} className={` job-opportunity-left absolute`}>
                            <JobOpportunity
                                needsDelay={previousActiveJobId===0}
                                descriptionLeft={false}
                                            handleSelect={() => {setActiveJobId(activeJobId === job.id ? 0 : job.id); setPreviousActiveJobId(activeJobId)}}
                                             job={job} active={activeJobId === job.id}/>
                        </div>
                    ))}
                    {jobOpportunitiesRight.map((job, index) => (
                        <div  key={job.id} style={{top: `${index * 165+50}px`, left:`${window.innerWidth-210}px`}} className={` job-opportunity-right absolute right-10 `}>
                            <JobOpportunity
                                needsDelay={previousActiveJobId===0}
                                descriptionLeft={true}
                                handleSelect={() => {setActiveJobId(activeJobId === job.id ? 0 : job.id); setPreviousActiveJobId(activeJobId)}}
                                            job={job} active={activeJobId === job.id}/>
                        </div>
                    ))}

                </div>
            </div>

        )
    } else
        return (
            <div className="relative p-8  max-lg:mb-[100vh]">
                <div data-aos={"fade-in"}
                     className="py-24 xl:pb-48 max-w-5xl mx-auto  uppercase koulen text-landing-yellow text-3xl lg:text-[40px] text-center">
                    Find out what you could be doing in the future and meet some people doing these jobs....
                </div>
                <div
                    className="relative min-h-screen flex max-xl:flex-col max-lg:justify-center items-center gap-8 max-lg:p-0 p-8 justify-between">
                    <div className=" flex flex-col gap-4">
                        {jobOpportunitiesLeft.map((job) => (
                            <div key={job.id} className="job-opportunity-left">
                                <JobOpportunity descriptionLeft={false}
                                                handleSelect={() => setActiveJobId(activeJobId === job.id ? 0 : job.id)}
                                                job={job} active={activeJobId === job.id}/>
                            </div>
                        ))}
                    </div>
                    <div className=" flex flex-col gap-4">
                        {jobOpportunitiesRight.map((job) => (
                            <div key={job.id} className="job-opportunity-right">
                                <JobOpportunity descriptionLeft={true}
                                                handleSelect={() => setActiveJobId(activeJobId === job.id ? 0 : job.id)}
                                                 job={job} active={activeJobId === job.id}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
}
export default JobOpportunities;