const AfterSchoolTexts = () => {
    return (
        <div className="max-w-7xl p-8 mx-auto flex flex-col gap-12 text-[24px]">
            <div  data-aos={"fade-up"} className="text-start w-full lg:w-2/3">After school you could start learning on the job by doing an
                apprenticeship or go to university to study in more detail
            </div>
            <div data-aos={"fade-up"} className="flex lg:justify-end lg:pr-24">
                <div className="text-start lg:pr-20">Then you could work for a space company that designs spacecraft
                    or uses satellite data</div>
            </div>
            <div data-aos={"fade-up"} className="flex justify-end lg:pr-12">
                <div className="text-start w-full lg:w-4/5">As a researcher studying the universe, the economics or
                    politics of space, or creating new technologies
                </div>
            </div>
            <div data-aos={"fade-up"} className="flex justify-end">
                <div className="text-start w-full lg:w-2/3">Or in government, helping to grow the space sector and support businesses.
                </div>
            </div>
        </div>
    )
}
export default AfterSchoolTexts;