const ScrollToTop = () => {
    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
            <button onClick={scrollUp} className="lg:w-[60px] flex flex-col  justify-center items-center z-50 p-2 !aspect-square text-landing-blue bg-landing-yellow rounded-md landing-button fixed top-12 right-8 sm:top-14 sm:right-14">
                <img  className="w-5 h-5 sm:w-7 sm:h-7 " src="landing/ScrollToTopRocket.svg" alt=""/>
                <div className="font-bold uppercase text-[9px] lg:text-[12px]">To top</div>
            </button>
    );
}
export default ScrollToTop;