import ShootingStar from "./ShootingStar";
import React, {useEffect, useRef, useState} from "react";
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger);


const EarthMars = () => {
    const earthMarsRef = useRef(null);
    const rocketRef = useRef(null);
    const [selfProgress, setSelfProgress] = useState(0);


    useEffect(() => {
        gsap.set(earthMarsRef.current, {autoAlpha: 0});
        gsap.to(earthMarsRef.current, {autoAlpha: 1, duration: 0.5});
        const scrollTrigger = gsap.to(earthMarsRef.current, {
            scrollTrigger: {
                trigger: "#earthMars",
                start: "top top",
                end: "+=1200",
                scrub: 1,
                pin: true,
                snap: 1,
                onUpdate: (self) => {
                    setSelfProgress(self.progress);
                    const newX = 400 * self.progress;
                    const newY = -556.34 * Math.pow(self.progress, 4) + 1848.68 * Math.pow(self.progress, 3) - 2164.65 * Math.pow(self.progress, 2)+ 818 * self.progress;
                    gsap.to(rocketRef.current, {
                        autoAlpha: self.progress < 0.05 || self.progress>0.95 ? 0 : 1,
                        x: newX,
                        y: -newY,
                        rotation: 90*self.progress,
                        overwrite: true,
                    });

                },
            }
        });

        return () => {
            scrollTrigger.scrollTrigger.kill();
            gsap.set(earthMarsRef.current, {autoAlpha: 1});
        };
    }, []);

    return (
        <div id="earthMars" className="relative overflow-hidden min-h-screen w-full max-w-full">

            <div
                className="absolute inset-0"
                style={{
                    backgroundImage: `url('landing/Starfield.svg')`,
                    backgroundRepeat: 'repeat-y',
                    backgroundSize: '100% auto',
                    backgroundPosition: 'top left',
                    opacity: 0.2,
                }}
            />
            <div ref={earthMarsRef}
                 className=" top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2  absolute w-screen">
                <div className="max-w-5xl mx-auto max-lg:flex-col flex gap-16 items-center justify-center p-8">
                    <div data-aos="fade-up" className=" text-[24px] text-start flex items-center">
                        This helps us to prepare for long missions to the Moon and Mars and also develop medicines and
                        materials for use on Earth
                    </div>
                    <img data-aos="fade-up" className=" hidden max-md:block h-[120px] mx-auto object-contain" src="landing/EarthMars.svg" alt=""/>
                    <div data-aos="fade-up" className="max-md:hidden relative shrink-0">
                        <img ref={rocketRef} className="rocket z-40 absolute h-10 top-[74px] left-0"
                             src="landing/rocket-solid.svg" alt=""/>
                        <img className="shrink-0  h-[250px] mx-auto object-contain" src="landing/EarthMars2.svg"
                             alt=""/>
                        <div
                            className={`${selfProgress > 0.1 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[48px] left-[45px]`}></div>
                        <div
                            className={`${selfProgress > 0.164 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[30px] left-[65px]`}></div>
                        <div
                            className={`${selfProgress > 0.228 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[14px] left-[85px]`}></div>
                        <div
                            className={`${selfProgress > 0.292 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[6px] left-[110px]`}></div>
                        <div
                            className={`${selfProgress > 0.356 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[4px] left-[135px]`}></div>
                        <div
                            className={`${selfProgress > 0.42 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[8px] left-[160px]`}></div>
                        <div
                            className={`${selfProgress > 0.484 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[16px] left-[185px]`}></div>
                        <div
                            className={`${selfProgress > 0.548 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[24px] left-[210px]`}></div>
                        <div
                            className={`${selfProgress > 0.612 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[35px] left-[235px]`}></div>
                        <div
                            className={`${selfProgress > 0.676 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[46px] left-[260px]`}></div>
                        <div
                            className={`${selfProgress > 0.74 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[60px] left-[285px]`}></div>
                        <div
                            className={`${selfProgress > 0.804 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[74px] left-[310px]`}></div>
                        <div
                            className={`${selfProgress > 0.868 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[94px] left-[335px]`}></div>
                        <div
                            className={`${selfProgress > 0.932 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[110px] left-[360px]`}></div>
                        <div
                            className={`${selfProgress > 0.996 ? 'block' : 'hidden'} rounded-full h-2 w-2 bg-white absolute top-[130px] left-[385px]`}></div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default EarthMars;