import React, { useEffect, useRef } from "react";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const SpacecraftsGSAP = () => {
    const sectionRefs = useRef([]);
    sectionRefs.current = [];

    const addToRefs = (el) => {
        if (el && !sectionRefs.current.includes(el)) {
            sectionRefs.current.push(el);
        }
    };


    useEffect(() => {
        const sections = sectionRefs.current;

        gsap.set(sections, { autoAlpha: 0 });
        gsap.to(sections[0], { autoAlpha: 1, duration: 0.5 });

        const scrollTrigger = gsap.to(sections, {
            scrollTrigger: {
                trigger: "#spacecrafts",
                start: "top top",
                end: "+=2500",
                scrub: 1,
                pin: true,
                snap: 1 / (sections.length - 1),
                onUpdate: (self) => {
                    const progress = self.progress * (sections.length - 1);
                    sections.forEach((section, index) => {
                        const isActive = Math.abs(index - progress) < 0.4;
                        gsap.to(section, {
                            autoAlpha: isActive ? 1 : 0,
                            duration: 0.5,
                            pointerEvents: isActive ? "auto" : "none",
                        });


                        if (index === 0) { // Spacecraft
                            gsap.to(".spaceship", {
                                autoAlpha: self.progress<0.05?0:1,
                                x:  window.innerWidth * 4 * (self.progress),
                                y:  window.innerHeight * 1.5 * (self.progress),
                                rotation: 13,
                                scaleX: 1 + 2.5 * self.progress,
                                scaleY: 1 + 2.5 * self.progress,
                                overwrite: true,
                            });
                        } else if (index === 1) { // Telescope
                            gsap.to(".telescope", {
                                rotation: 20 * (self.progress - index),
                                scaleX:  1-0.35 * self.progress,
                                scaleY:  1-0.35 * self.progress,
                                overwrite: true,
                            });
                        } else if (index === 2) { // Rover
                            gsap.to(".rover", {
                                x:  -window.innerWidth/3 * (self.progress),
                                scaleX: 1 + 1.35 * self.progress,
                                scaleY: 1 + 1.35 * self.progress,
                                overwrite: true,
                            });
                        }
                    });
                },
            }
        });

        return () => {
            scrollTrigger.scrollTrigger.kill();
            gsap.set(sections, { autoAlpha: 1, duration: 0.5 });
        };
    }, []);

    return (
        <div id="spacecrafts" className="relative overflow-hidden min-h-screen">
            <div
                className="absolute inset-0"
                style={{
                    backgroundImage: `url('landing/Starfield.svg')`,
                    backgroundRepeat: 'repeat-y',
                    backgroundSize: '100% auto',
                    backgroundPosition: 'top left',
                    opacity: 0.2,
                }}
            />

            <div ref={addToRefs} className="section absolute inset-0 flex flex-col gap-16 items-center justify-center">
                <div data-aos="fade-up" className="fredoka-bold text-[24px] text-center px-4">
                    There are also spacecraft travelling to distant planets...
                </div>
                <div className="h-[350px] w-full flex relative justify-start relative">
                    <img
                        className="absolute -start-[30%] opacity-0 spaceship mb-12 lg:my-24 -rotate-20 h-[92px]"
                        src="landing/Spaceship.svg" alt="Spaceship"
                    />
                </div>
            </div>

            <div ref={addToRefs} className="section absolute inset-0 flex flex-col gap-16 items-center justify-center">
                <div data-aos="fade-up" className="fredoka-bold text-[24px] text-center px-4">
                    ...big telescopes being used to understand how the universe and life formed and developed...
                </div>
                <img
                    className="telescope w-full rotate-6 h-[350px]"
                    src="landing/Telescope.svg" alt="Telescope"
                />
            </div>

            <div ref={addToRefs} className="section absolute inset-0 flex flex-col gap-16 items-center justify-center">
                <div data-aos="fade-up" className="fredoka-bold text-[24px] text-center px-4">
                    ...and rovers on Mars.
                </div>
                <div className="h-[350px] w-full relative">
                <img
                    className="absolute top-[35%] right-[10%] rover h-[60px] md:h-[150px]"
                    src="landing/Rover.svg" alt="Rover"
                />
                </div>
            </div>

        </div>
    );
};

export default SpacecraftsGSAP;
