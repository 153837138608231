import {Link, Link as RouterLink} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {useEffect} from "react";
import ShootingStar from "./ShootingStar";

const Hero = () => {

    useEffect(() => {
        const floatingText = document.getElementById('floatingText');
        let timeout;

        const handleScroll = () => {
            clearTimeout(timeout);
            floatingText.classList.add('scroll-stop');
            floatingText.classList.remove('animated-text');// Stop animation smoothly

            timeout = setTimeout(() => {
                floatingText.classList.remove('restart-animation');
            }, 1000); // 1 second timeout after scroll
        };

        const handleScrollToTop = () => {
            if (window.scrollY === 0) {
                floatingText.classList.remove('scroll-stop');
                floatingText.classList.add('animated-text');
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScrollToTop);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('scroll', handleScrollToTop);
        };
    }, []);



    return (
        <div className="  relative pb-20">
            <div className="px-6 pt-6 relative w-full justify-between flex  ">

                <Link to={'/'}>
                <img className=" z-40  max-lg:w-[100px] w-[150px]"
                     src="landing/new_scuk_logo_alpha.png" alt=""/>
                </Link>

                <div className="text-[12px] lg:text-[18px] opacity-30 uppercase pr-2">Beta version</div>

            </div>
            <div data-aos="fade-up" className=" min-h-screen flex flex-col justify-center gap-12 py-6">
                <div id="floatingText" className="animated-text px-8 uppercase krona text-3xl xs:text-4xl sm:text-5xl md:text-6xl lg:text-6xl xl:text-[112px] text-center">
                    <span>U</span>
                    <span>K</span>
                    <span className="!text-transparent">i</span>
                    <span>S</span>
                    <span>P</span>
                    <span>A</span>
                    <span>C</span>
                    <span>E</span>
                    <span className="!text-transparent">i</span>
                    <span>1</span>
                    <span>0</span>
                    <span>1</span>
                </div>
                <div className=" px-8 uppercase koulen text-landing-yellow text-3xl lg:text-[40px] text-center">Find your
                    place in space
                </div>
                {/*<div className="flex flex-col items-center gap-8">*/}
                {/*    <div className="px-8 text-2xl text-center">Which of these best describes you?</div>*/}
                {/*    <div className="w-full relative">*/}

                {/*        <div className=" px-8 absolute top-0 flex-wrap flex justify-center w-full gap-8">*/}
                {/*            <HashLink*/}
                {/*                to="#space"*/}
                {/*                className="landing-button py-2.5 text-center rounded-md bg-landing-yellow fredoka-bold text-landing-blue text-2xl w-[350px]">*/}
                {/*                I’m at school*/}
                {/*            </HashLink>*/}
                {/*            <HashLink*/}
                {/*            to="#space"*/}
                {/*            className="landing-button py-2.5 text-center rounded-md bg-landing-yellow fredoka-bold text-landing-blue text-2xl w-[350px]">*/}
                {/*            I’m a student or professional*/}
                {/*        </HashLink>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <ShootingStar className="absolute -bottom-16 left-[15%]"/>
        </div>

    );
};
export default Hero;