import {Link} from "react-router-dom";

const LandingFooter = () => {
    return (
        <div className="flex  px-12 pb-16 justify-between gap-16 max-lg:flex-col max-lg:items-center">
            <div className="flex gap-16 max-lg:flex-col">
                <div className="flex flex-col justify-between gap-y-10">
                    <Link to={'/'}>
                        <img className="h-[90px] w-[200px]" src="landing/new_scuk_logo_alpha.png" alt=""/>
                    </Link>
                    <div className="max-w-[330px] flex gap-4 text-[16px]">
                        We are a not-for-profit, registered as a company limited by guarantee, No. 14547249
                        © SpaceCareers.uk Limited 2024
                    </div>
                </div>
                <div className="flex flex-col  gap-y-6">
                    <div className="flex flex-col gap-y-0">
                        <div className="text-2xl font-bold">For Candidates</div>
                        <Link className="hover:underline" to={"/jobs"}>Jobs</Link>
                        <Link className="hover:underline" to={"/articles/advice_and_information"}>Advice &
                            Information</Link>
                        <Link className="hover:underline" to={"/articles/job_profiles"}>Job profiles</Link>
                        <Link className="hover:underline" to={"/articles/career_stories"}>Career Stories</Link>
                        <Link className="hover:underline" to={"/events"}>Events</Link>
                        <Link className="hover:underline" to={"/employers"}>Company A-Z</Link>
                        <Link className="hover:underline" to={"/signup"}>Create your profile</Link>
                        <Link className="hover:underline" to={"/login"}>Login</Link>
                    </div>
                    <div className="flex flex-col gap-y-0">
                        <div className="text-2xl font-bold">For Employers</div>
                        <Link className="hover:underline" to={"/advertise"}>Advertise</Link>
                        <Link className="hover:underline" to={"/signup"}>List your organisation</Link>
                        <Link className="hover:underline" to={"/login"}>Login</Link>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-between  gap-y-10 max-lg:gap-16">
                <div className="flex gap-2 justify-end max-lg:justify-center">
                    <a target="_blank" href="https://www.facebook.com/spacecareersuk/?locale=en_GB"><img src="landing/facebook.svg" alt=""/></a>
                    <a target="_blank" href="https://www.instagram.com/spacecareersuk/"><img src="landing/instagram.svg" alt=""/></a>
                    <a target="_blank" href="https://www.linkedin.com/company/spacecareers-uk?originalSubdomain=uk"><img src="landing/linkedin.svg" alt=""/></a>
                    <a target="_blank"  href="https://twitter.com/SpaceCareersUK"><img src="landing/twitter.svg" alt=""/></a>
                </div>
                <img className="h-[62px] w-[232px]" src="landing/ukseds-logo.png" alt=""/>
            </div>
        </div>
    )
}
export default LandingFooter;
